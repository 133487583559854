//=================
.evaluat-hero-main-area {
  min-height: 620px;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 576px) {
    .container {
      width: 100vw !important;
    }
  }
  .evaluat-inner-container {
    width: calc(100% - 150px);
    @media (max-width: 991px) {
      width: 100%;
    }
    h2 {
      color: #0a1629;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 78px; /* 130% */
      letter-spacing: -1.2px;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        font-size: 44px;
        line-height: 1.3;
        br {
          display: none;
        }
      }
      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 1.3;
      }
      @media (max-width: 767px) {
        font-size: 26px;
        line-height: 1.3;
      }
    }
    p {
      color: #0a1629;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.48px;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 1.2;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 1.2;
      }
    }
    a {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.4px;
      display: inline-flex;
      align-items: center;
      gap: 6px;
    }
  }
  img.evaluat-common-right-image {
    position: absolute;
    right: 0;
    top: 0;
  }
}

//=========

.evaluat-partner-full-area {
  margin: 60px 0;
  padding-top: 20px;
  .partner-company-image-list {
    .rfm-marquee {
      gap: 50px;
    }
    .rfm-initial-child-container {
      gap: 50px;
    }

    img {
      height: 60px;
      @media (max-width: 991px) {
        height: 40px;
      }
    }
  }
}

//=======================
.evaluat-step-full-area {
  margin: 80px 0;
  .evaluat-step-header {
    text-align: center;
    width: 800px;
    margin: auto;
    @media (max-width: 991px) {
      width: calc(100% - 100px);
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    h3 {
      color: #0a1629;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 1.3;
      }
    }
    p {
      color: #0a1629;
      text-align: center;
      font-family: Sora;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
      margin-bottom: 30px;
    }
    .evaluat-step-line-area {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: 40%;
        height: 2px;
        background: rgba(196, 196, 196, 1);
        @media (max-width: 767px) {
          width: 30%;
        }
      }
      i {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2ba3c2;
        border-radius: 50%;
        font-size: 22px;
        color: #fff;
        &:last-child {
          background: rgba(196, 196, 196, 1);
        }
      }
    }
  }
}

//=================
.evaluat-ability-full-area {
  padding: 80px 0;
  background-position: bottom center;
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 80px;

  .evaluat-ability-left-content {
    width: calc(100% - 100px);
    position: relative;
    top: -40px;
    @media (max-width: 991px) {
      width: calc(100% - 60px);
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    .common-button-hrx {
      display: inline-flex;
      gap: 6px;
      i {
        font-size: 18px;
      }
    }
    h3 {
      color: #000;
      font-family: Sora;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 44px;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 1.3;
      }
    }
    p {
      color: #0a1629;
      font-family: Sora;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
  }
  .evaluat-ability-right-content {
    padding-bottom: 90px;
    &.personality-test {
      padding-bottom: 0;
      position: relative;
      bottom: -10px;
      img {
        position: relative;
        bottom: -30px;
      }
    }
    img {
      width: 100%;
    }
  }
}

//============
.evaluat-experience-full-area {
  margin: 80px 0;
  margin-top: 0;
  .evaluat-experience-inner-container {
    .single-evaluat-experience-item {
      text-align: center;
      svg {
        width: 80px;
        height: 80px;
      }
      h4 {
        margin-top: 20px;
        color: #0a1629;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }
}

//===============

.fit-assessments-full-area {
  background: #12859e;
  padding: 80px 0;
  .fit-assessments-inner-container {
    .fit-assessments-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      flex-wrap: wrap;
      .fit-assessment-header-left {
        width: calc(100% - 200px);
        @media (max-width: 767px) {
          width: 100%;
        }
        h3 {
          color: #fff;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 44px;
          @media (max-width: 767px) {
            font-size: 28px;
            line-height: 1.3;
          }
        }
        p {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .fit-assessment-header-right a {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 6px;
        border: 1px solid #fff;
        padding: 12px 20px;
        border-radius: 8px;
        i {
          font-size: 18px;
        }
      }
    }
    .fit-assessments-content {
      .single-fit-assessment-content-box {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        .fit-assessment-icon-right {
          transform: rotateY(90deg);
          transform-origin: right center;
          visibility: hidden;
          transition: 0.3s;
          position: relative;
          right: -15px;
          svg {
            path {
              fill: #000;
            }
          }
        }
        &:hover {
          .fit-assessment-icon-right {
            transform: rotateY(0deg);
            transform-origin: right center;
            visibility: visible;
            transition: 0.3s;
            right: 0px;
            svg {
              path {
                fill: #000;
              }
            }
          }
        }
      }
    }
  }
}

//============
.evaluat-personality-result-area {
  margin: 80px 0;
  .evaluat-personality-result-inner-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      justify-content: center;
      gap: 12px;
    }
    .single-result-chart-data {
      width: 18%;
      border-radius: 10px;
      @media (max-width: 991px) {
        width: 23%;
        margin-bottom: 16px;
      }
      @media (max-width: 767px) {
        width: 31%;
        margin-bottom: 0px;
        justify-content: center;
        gap: 16px;
      }
      @media (max-width: 576px) {
        width: 47%;
        margin-bottom: 0px;
        justify-content: center;
        gap: 16px;
      }
      @media (max-width: 450px) {
        width: 100%;
        margin-bottom: 0px;
        justify-content: center;
        gap: 0px;
      }
      h3 {
        padding: 20px;
        padding-bottom: 0;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
}
