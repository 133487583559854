.header-main-area-wrap {
  background: #fff;
  position: sticky;
  top: -2px;
  z-index: 9999;
  padding: 10px 0;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0px 10px 20px #c4cbd633;
  @media (max-width: 576px) {
    .container {
      width: 100% !important;
    }
  }
  .header-bottom-area-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .header-logo-area-left {
      h4 {
        margin-bottom: 0;
      }
      img {
        height: 40px;
      }
    }
    .header-navbar-items-area {
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      justify-content: flex-end;
      .nav-toggle-button-wrap {
        position: absolute;
        right: 0;
      }
      button.nav-toggle-menu {
        border: none;
        background: none;
        width: 24px;
        height: 24px;
        text-align: center;
        padding: 0;
        display: none;
        @media (max-width: 991px) {
          display: block;
        }
        &.show-nav-button {
          span {
            transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            margin-bottom: 0;
            &:nth-child(1) {
              transform: rotate(45deg);
              transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
              transform-origin: center center;
              margin-bottom: 0;
            }
            &:nth-child(3) {
              transform: rotate(-45deg);
              transform-origin: center center;
              transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
              margin-bottom: 0px;
              position: relative;
              bottom: 1px;
            }
            &:nth-child(2) {
              display: none;
              transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
          }
        }
        span {
          width: 24px;
          height: 2px;
          display: block;
          background: #000;
          margin-bottom: 6px;
          transition: 0.5s;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .hide-nav-button {
          span {
            transition: 0.5s;
          }
        }
      }
      .responsive-nav-items {
        ul {
          display: flex;
          gap: 40px;
          margin-bottom: 0;
          align-items: center;
          padding-left: 0;
          li {
            list-style: none;
            &.active {
              a {
                color: #2ba3c2;
                font-weight: 600;
              }
            }
            a {
              color: #000;
              font-size: 14px;
              &.active-link {
                color: #2ba3c2;
              }
              &.common-button-hrx {
                color: #fff;
              }
            }
          }
        }
        &.nav-toggle-menu-hide {
          @media (max-width: 991px) {
            position: absolute;
            right: 0;
            width: 100%;
            background: #fff;
            top: 20px;
            padding: 30px;
            transform: rotateX(90deg);
            transition: 0.3s;
            transform-origin: top center;
            ul {
              display: flex;
              gap: 40px;
              margin-bottom: 0;
              align-items: center;
              flex-direction: column;
              li {
                list-style: none;
                &.active {
                  a {
                    color: #2ba3c2;
                    font-weight: 600;
                  }
                }
                a {
                  color: #000;
                  font-size: 14px;
                  &.active-link {
                    color: #2ba3c2;
                  }
                  &.common-button-hrx {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        &.nav-toggle-menu-show {
          @media (max-width: 991px) {
            position: absolute;
            right: 0;
            width: 100%;
            background: #fff;
            top: 40px;
            padding: 30px;
            transform: rotateY(0deg);
            ul {
              display: flex;
              gap: 40px;
              margin-bottom: 0;
              align-items: center;
              flex-direction: column;
              li {
                list-style: none;
                &.active {
                  a {
                    color: #2ba3c2;
                    font-weight: 600;
                  }
                }
                a {
                  color: #000;
                  font-size: 14px;
                  &.active-link {
                    color: #2ba3c2;
                  }
                  &.common-button-hrx {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .evaluate-nav {
        display: inline-block;

        font-size: 16px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        color: #3498db;
        background-color: transparent;
        border: 1px solid #3498db;
        border-radius: 6px; /* Adjust the border radius as needed */
        transition: 0.3s ease, background-color 0.3s;
        animation: animateBorder 4s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
        overflow: hidden;
        a {
          padding: 10px 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          color: #2ba3c2 !important;
          // border-right: 1px solid #2ba3c2;
          // border-bottom: 1px solid #2ba3c2;
          // border-left: 1px solid #2ba3c2;
          background: #f2fcff;
        }
      }

      @keyframes animateBorder {
        0%,
        100% {
          border-top-color: #3498db;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
        25% {
          border-top-color: transparent;
          border-right-color: #3498db;
          border-bottom-color: transparent;
          border-left-color: transparent;
        }
        50% {
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: #3498db;
          border-left-color: transparent;
        }
        75% {
          border-top-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
          border-left-color: #3498db;
        }
      }

      .animated-button:hover {
        color: #fff;
        background-color: #3498db;
        animation: none; /* Pause the rotation on hover */
      }
    }
  }
}
