//=======================

.hr-faq-full-area {
  margin: 80px 0;
  .hr-faq-inner-container {
    margin: auto;
    max-width: 650px;
    width: 100%;
    .hr-faq-header {
      text-align: center;
      margin-bottom: 40px;
    }
    .hr-faq-content-wrap {
      .accordion {
        border: none;
        .accordion-item {
          border: none;
          border-bottom: 1px solid #efefef;
          border-radius: 0px;
          h2.accordion-header {
            .accordion-button {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              background: #fff;
              // padding-left: 0;
              font-weight: 600;
              // padding-right: 0;
              &:focus {
                z-index: 3;
                // border-color: transparent;
                outline: 0;
                box-shadow: none;
              }
              &:not(.collapsed) {
                color: black;
              }
            }
          }
          .accordion-button::after {
            flex-shrink: 0;
            width: var(--bs-accordion-arrow);
            height: var(--bs-accordion-arrow);

            background-size: var(--bs-accordion-arrow);
            transition: var(--bs-accordion-btn-icon-transition);
          }
          .accordion-collapse {
            .accordion-body {
              // padding-left: 0;
              padding-top: 8px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
