//===============
.hr-works-full-area {
  background: #f2f2f2;
  padding: 60px 0;
  .hr-works-header-title {
    padding-bottom: 40px;
    text-align: center;
    h3 {
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
    }
  }
  .hr-single-work-item {
    text-align: center;
    svg {
      height: 150px;
    }
  }
}
