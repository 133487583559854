//=====================
.hr-analytics-full-area-wrap {
  //   min-height: 800px;
  height: 100%;
  background-position: right top;
  background-repeat: no-repeat;
  background-color: #f3f3f3;
  .hr-analytics-content-area {
    padding: 80px 0;
    .hr-analytics-header-title {
      text-align: center;
      padding-bottom: 20px;
      h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
      }
    }
    .hr-analytics-body-content {
      text-align: center;
      img {
        width: 80%;
      }
    }
  }
}
