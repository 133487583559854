//=======================
.hr-optimize-full-area {
  padding: 60px 0;
  .hr-optimize-content-area {
    .hr-optimize-header-title {
      text-align: center;
      padding-bottom: 40px;
      h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
      }
    }
    .hr-optimize-content-left {
      h5 {
        font-size: 24px;
        font-weight: 600;
      }
      .hr-optimize-resume-button-area {
        display: flex;
        flex-direction: column;
        gap: 20px;
        *.hr-resume-scan-for-free-button {
          border: 2px solid #2ba3c2;
          color: #2ba3c2;
          display: block;
          text-align: center;
          padding: 10px 20px;
          font-weight: 600;
          border-radius: 6px;
          &.linkedin-check-button {
            background: #2ba3c2;
            color: #fff;
          }
        }
        .hr-optimize-resume-button-divider {
          display: flex;
          justify-content: space-between;
          align-items: center;
          b {
            color: #000;
          }
          span {
            width: 48%;
            height: 2px;
            background: #333;
            display: block;
          }
        }
      }
    }
    .hr-optimize-content-right {
      img {
        width: 100%;
        padding-left: 50px;
      }
    }
  }
}
