//=============
.feedback-form-header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  h6 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}
//==========
.feedback-form-steps-area {
  height: 150px;
  display: flex;
  align-items: center;
  background: #fff;
  @media (max-width: 767px) {
    height: auto;
    padding: 20px 0;
  }
  .ant-steps.ant-steps-label-vertical {
    .ant-steps-item-content {
      display: block;
      width: 130px;
      margin-top: 12px;
      text-align: center;
      .ant-steps-item-title {
        font-size: 14px !important;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}

//==============
.form-steps-area-wrap {
  .form-single-card {
    border-radius: 16px;
    border: 1px solid #aec3c8;
    background: #fff;
    padding: 24px;
    h5 {
      color: #344054;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    .ant-form-item-control-input-content {
      > * {
        width: 100%;
      }
      .ant-picker-input {
        input {
          height: 30px;
        }
      }
      input {
        height: 40px;
      }
    }
    .ant-form-item-label {
      label {
        color: #344054;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .table {
    thead {
      tr {
        th {
          background: #2ba3c2;
          color: #fff;
        }
      }
    }
    tbody {
      tr {
        td {
          background: #fff;
          .ant-form-item {
            margin-bottom: 0;
          }
          input {
            border: none;
            box-shadow: 0 0 0 0px rgba(5, 145, 255, 0.1);
          }
        }
      }
    }
  }
  .form-submit-button-area {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-top: 30px;
  }
}

//============
.radio-ques-items {
  position: relative;
  width: fit-content;
  span.disagree-label {
    position: absolute;
    bottom: 6px;
  }
  span.agree-label {
    position: absolute;
    bottom: 5px;
    left: 195px;
  }
  // &.radio-item-1 {
  //   span.agree-label {
  //     right: 18%;
  //     @media (max-width: 480px) {
  //       right: 0%;
  //     }
  //   }
  // }
  // &.radio-item-2 {
  //   span.agree-label {
  //     right: 7%;
  //     @media (max-width: 480px) {
  //       right: 0%;
  //     }
  //   }
  // }
  // &.radio-item-3 {
  //   span.agree-label {
  //     right: 22%;
  //     @media (max-width: 480px) {
  //       right: 0%;
  //     }
  //   }
  // }
  // &.radio-item-4 {
  //   span.agree-label {
  //     right: 24%;
  //     @media (max-width: 480px) {
  //       right: 0%;
  //     }
  //   }
  // }
  // &.radio-item-5 {
  //   span.agree-label {
  //     right: 33%;
  //     @media (max-width: 480px) {
  //       right: 0%;
  //     }
  //   }
  // }

  // .ant-form-item {
  //   padding-left: 70px;
  //   @media (max-width: 480px) {
  //     padding-left: 50px;
  //   }
  // }
  .ant-col.ant-form-item-label {
    position: relative;
    // left: -70px;
  }
  .ant-form-item-control-input-content {
    // display: flex;
    // justify-content: flex-start;
    // gap: 18px;
    > * {
      width: auto !important;
      label.ant-radio-wrapper {
        &.ant-radio-wrapper-in-form-item {
          span.ant-radio.ant-wave-target {
            width: 0;
            height: 0;
            visibility: hidden;
            input {
              width: 0;
              height: 0;
              visibility: hidden;
            }
          }
          span {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            border: 1px solid #ccc;

            background: #fff;
            color: #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
          }
        }
        &.ant-radio-wrapper-checked {
          > span {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: #e27f11;
            border: 1px solid #e27f11;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
          }
        }
      }
    }
  }
}

//============
table.add-new-field-form {
  tbody {
    tr {
      td {
        border: 1px solid #dde1e8;
        &:first-child {
          width: 100px;
        }
      }
      &:last-child {
        td {
          border: none;
        }
      }
    }
  }
  .ant-form-item.new-field-add-btn {
    width: 100px;
  }
}

//========
.next-step-form-btn {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px 20px;
  height: 40px;
  background: #e27f11;
}
.prev-step-form-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 20px;
  height: 40px;
  border: 1px solid #344054;
  background: #fff;
  color: #344054;
  svg {
    right: 0;
  }
}

//================
.form-submission-confirm {
  .ant-modal-header {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .ant-modal-body {
    margin-top: 20px;
    margin-bottom: 24px;
    h6 {
      color: #344054;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      color: #475467;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      width: 48%;
      button {
        width: 100%;
        &.ant-btn-default {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 8px;
          padding: 14px 20px;
          height: 40px;
          border: 1px solid #344054;
          background: #fff;
          color: #344054;
        }
        &.ant-btn-primary {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 14px;
          padding: 14px 20px;
          height: 40px;
          background: #e27f11;
          color: #fff;
        }
      }
    }
  }
}

//=================
.form-submit-success-page {
  max-width: 650px;
  margin: auto;
  padding: 20px 0;
  .form-submit-successfully-inner {
    text-align: center;
    img {
      width: 500px;
    }
    p {
      color: #344054;
      text-align: center;
      font-family: Sora;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
    }
  }
}

//===
.form-steps-area-wrap {
  .ant-radio-group.ant-radio-group-outline {
    display: flex;
    gap: 2px;
    position: relative;
    left: 64px;
    label.ant-radio-wrapper.ant-radio-wrapper-in-form-item {
      margin: 0;
    }
  }
}
