//================
.footer-full-area-wrap {
  background: #0c4854;
  text-align: center;
  padding: 60px 0;
  padding-bottom: 0px;

  .footer-inner-area-wrap {
    .footer-content-copyright-area {
      padding-bottom: 26px;
      img {
        display: inline-block;
        margin-bottom: 16px;
      }
      p {
        color: #fff;
        margin-bottom: 30px;
      }
      .footer-social-area {
        display: flex;
        justify-content: center;
        gap: 20px;
        a {
          color: #0c4854;
          background: #fff;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          font-size: 24px;
        }
      }
    }
    hr {
      color: #fff;
      margin: 0;
    }
    > p {
      margin-bottom: 0;
      padding: 20px 0;
      color: #fff;
    }
  }
}
