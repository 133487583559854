.hero-full-area {
  height: calc(100vh - 60px);
  position: relative;
  background: #f3fafc;
  .bg-video-player-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      // background: #ffffffba;
      background: rgb(8 32 37 / 87%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    video {
      object-fit: cover;
    }
  }
  .hero-right-banner-image {
    position: relative;
    height: calc(100vh - 50px);
    img {
      position: absolute;
      height: 100%;
      top: -30px;
      z-index: 999;
    }
  }
  .hero-content-left {
    h4 {
      font-size: 48px;
      font-weight: 700;
      line-height: 64px;
      color: #fff;
      margin-bottom: 30px;
    }
  }
}
