@import "assets/scss/style.scss";
body {
  background: #ffffff;
  a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
}
.social_icon a {
  font-size: 26px;
  display: block;
  color: #b4b4b4;
}
