//====================================
.hr-feature-full-area-wrap {
  padding: 60px 0;
  .hr-feature-header {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
    .hr-feature-header-left {
      width: 48%;
      h3 {
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        color: #1a1a1a;
        margin-bottom: 0;
      }
    }
    .hr-feature-header-right {
      width: 48%;
      p {
        margin-bottom: 0;
      }
    }
  }
  .hr-feature-info-content-area {
    min-height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin-top: 40px;
    height: 100%;
    position: relative;
    .hr-feature-single-info-wrap {
      .single-feature-info {
        position: absolute;
        transition: 0.5s;
        &:nth-child(1) {
          top: 40%;
          left: 5%;
        }
        &:nth-child(2) {
          top: 16%;
          left: 20%;
        }
        &:nth-child(3) {
          bottom: 14%;
          left: 20%;
        }
        &:nth-child(4) {
          top: 13%;
          left: 50%;
        }
        &:nth-child(5) {
          bottom: 18%;
          left: 45%;
        }
        &:nth-child(6) {
          top: 23%;
          right: 10%;
        }
        &:nth-child(7) {
          bottom: 20%;
          right: 10%;
        }
        .single-feature-info-inner-content {
          background: #fff;
          border-radius: 6px;
          padding: 8px 12px;
          transition: 0.5s;
          p {
            margin-bottom: 0;
            color: #2ba3c2;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            i {
              width: 24px;
              height: 24px;
              border: 1px solid;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              font-weight: 400;
              font-size: 20px;
            }
            .svg-icon {
              border-radius: 4px;
              background: #2ba3c2;
              width: 24px;
              height: 24px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &.show-card {
            max-width: 300px;
            padding: 20px;
            border-radius: 8px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            background: rgba(255, 255, 255, 0.1);

            /* Background blur / lg */
            backdrop-filter: blur(12px);
            .svg-icon.expand-content {
              border-radius: 4px;
              background: #fff;
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 12px;
              svg {
                width: 30px;
                height: 30px;
                path {
                  fill: #2ba3c2;
                }
              }
            }
            h4 {
              font-size: 18px;
              color: #fff;
            }
            p {
              margin-bottom: 0;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              font-size: 14px;
            }
            h6 {
              margin-bottom: 0;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              margin-top: 20px;
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
              i {
                width: 20px;
                height: 20px;
                border: 1px solid;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                font-weight: 400;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
