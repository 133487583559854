//================================
.saas-feature-full-area-wrap {
  padding: 60px 0;
  .saas-feature-title {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 40px;
    h3 {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    p {
      padding: 0px 40px;
    }
  }
  .saas-signle-feature {
    padding-right: 100px;
    img {
      height: 40px;
      margin-bottom: 20px;
    }
    h4 {
      margin-bottom: 10px;
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
