@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

@import "common.scss";
@import "header.scss";
@import "footer.scss";
@import "home/hero.scss";
@import "home/hr-feature.scss";
@import "home/hr-analytics.scss";
@import "home/hr-optimize.scss";
@import "home/hr-works.scss";
@import "home/faq.scss";

@import "sassFeature/sassFeature.scss";
@import "evaluatX/evaluatx.scss";
body {
  font-family: "Lexend", sans-serif;
}
