$dashboard_card1: #664dc9;
$dashboard_card2: #17c979;
$dashboard_card3: #ffab00;
$dashboard_card4: #3e80eb;
$dashboard_card5: #ef4b4b;
$dashboard_card6: #06c0d9;

$pmx_primary: #ffa959;
$pmx_success: #2ca67a;
$pmx_primary_hover: #ffb570;
$pmx_primary_active_bg: #ffa9591a;
$pmx_border: #d9d9d9;
$pmx_border_danger: #f46a6a;
$pmx_gray: #bdbdbd;
$pmx_light_blue: #8181a5;
$pmx_secondary: #2ba3c2;
$pmx_dropdown_color: #a8a8a8;
$pmx_blue: #058de0;

$hrx_blue: #088ab2;
$hrx_white_blue: #2ba3c2;
$hrx_dark_blue: #0c4854;
$hrx_black: #111928;
$hrx_light_black: #1f2a37;
$hrx_red: #eb2027;
$hrx_success: #027a48;
$hrx_success_lite: #d1fadf;
$hrx_danger: #ed8c1a;
$hrx_danger_lite: #ed8c1a1f;
$hrx_yellow: #ffc107;
$hrx_yellow_lite: #ffc1071f; ;
